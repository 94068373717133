import { render, staticRenderFns } from "./modal-guard-container.vue?vue&type=template&id=57bf3cf6"
import script from "./modal-guard-container.vue?vue&type=script&lang=ts"
export * from "./modal-guard-container.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuModal: require('/mnt/vss/_work/2/s/components/nu/modal.vue').default})
