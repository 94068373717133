import { render, staticRenderFns } from "./button.vue?vue&type=template&id=92d29e8c"
import script from "./button.vue?vue&type=script&lang=ts"
export * from "./button.vue?vue&type=script&lang=ts"
import style0 from "./button.vue?vue&type=style&index=0&id=92d29e8c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FaIcon: require('/mnt/vss/_work/2/s/components/fa-icon.vue').default})
