import { render, staticRenderFns } from "./welcome.vue?vue&type=template&id=389d924d&scoped=true"
import script from "./welcome.vue?vue&type=script&lang=ts"
export * from "./welcome.vue?vue&type=script&lang=ts"
import style0 from "./welcome.vue?vue&type=style&index=0&id=389d924d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "389d924d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonNotifications: require('/mnt/vss/_work/2/s/components/common/notifications.vue').default,CommonModalContainer: require('/mnt/vss/_work/2/s/components/common/modal-container.vue').default})
